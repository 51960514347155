<template>
  <div>
    <v-navigation-drawer permanent expand-on-hover app>
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img
              src="https://randomuser.me/api/portraits/women/28.jpg"
            ></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title class="text-h6"> 管理员 </v-list-item-title>
            <v-list-item-subtitle>Tupenpen</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item link @click="changeNav('/')">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-title>概览</v-list-item-title>
        </v-list-item>

        <v-list-group prepend-icon="mdi-database" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>战舰数据管理</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link @click="changeNav('/ship_name')">
            <v-list-item-content>
              <v-list-item-title>战舰名称（翻译）</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="changeNav('/ship_score')">
            <v-list-item-content>
              <v-list-item-title>战绩评分（定时任务）</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group prepend-icon="mdi-cog-outline" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>系统管理</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link @click="changeNav('/sys_publish')">
            <v-list-item-content>
              <v-list-item-title>发布公告</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <router-view />
  </div>
</template>

<script>
export default {
  data(){
      return{

      }
  },

  methods: {
    changeNav(navpath) {
        this.$router.push({
          path: navpath,
        });
    },
  },
};
</script>