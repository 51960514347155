<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    sort-desc
    class="elevation-1 frame"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>更新执行情况</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>

    <template v-slot:item.statu="{ item }">
      <v-chip dark color="green">
        {{ item.statu }}
      </v-chip>
    </template>

    <template v-slot:no-data>
      暂无数据
      <!-- <v-btn color="primary" @click="initialize"> 重置 </v-btn> -->
    </template>
    <v-snackbar v-model="snackbar" :timeout="timeout" :multi-line="multiLine">
      {{ alertText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-data-table>
</template>
<script>
export default {
  name: "ShipScore",
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "执行编号",
        align: "start",
        sortable: false,
        value: "id",
      },
      { text: "执行结果", sortable: false, value: "statu" },
      { text: "执行时间", sortable: true, value: "created" },
    ],
    desserts: [],

    multiLine: true,
    snackbar: false,
    timeout: 2000,
    alertText: `I'm a multi-line snackbar.`,
  }),

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$axios({
        method: "get",
        url: "/api/manage/ship_score/task_statu",
        data: {},
      })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(this.desserts)
            var tmp = JSON.parse(JSON.stringify(res.data.data));
            for (let i in tmp) {
              tmp[i].created = this.timestamp(tmp[i].created);

              if (tmp[i].statu == 1) {
                tmp[i].statu = "正常";
              }else{
                tmp[i].statu = "异常";
              }
            }
            this.desserts = tmp;
          } else {
            this.alertText = res.data.msg;
            this.snackbar = true;
          }
          // this.desserts = JSON.stringify(res.data.data); 对象转json字符串
        })
        .catch((res) => {
          this.alertText = "服务端错误！";
          this.snackbar = true;
          console.log(res);
        });
    },
  },
};
</script>
<style>
.frame {
  margin-top: 18px;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
