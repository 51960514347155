<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    :search="search"
    class="elevation-1 frame"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>战舰名称管理</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="搜索ID或名称"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mb-2" @click="initializeNoZhcn">
          查询未翻译
        </v-btn>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <v-btn color="teal" dark class="mb-2" @click="checkNewShips">
          检查新战舰
        </v-btn>
        <v-dialog v-model="dialog" max-width="650px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.ShipId"
                      label="战舰ID"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.ShipName"
                      label="战舰名称"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> 取消 </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                {{ formButton }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
    </template>
    <template v-slot:no-data>
      暂无公告
      <!-- <v-btn color="primary" @click="initialize"> 重置 </v-btn> -->
    </template>
    <v-snackbar v-model="snackbar" :timeout="timeout" :multi-line="multiLine">
      {{ alertText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-data-table>
</template>

<script>
export default {
  name: "ShipName",
  data: () => ({
    search: "",

    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "战舰ID",
        align: "start",
        sortable: false,
        value: "ShipId"
      },
      { text: "战舰名称", sortable: false, value: "ShipName" },
      { text: "操作", value: "actions", sortable: false }
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      ShipId: "",
      ShipName: ""
    },
    defaultItem: {
      ShipId: "",
      ShipName: ""
    },

    multiLine: true,
    snackbar: false,
    timeout: 2000,
    alertText: `提示框`
  }),

  computed: {
    formTitle() {
      return "编辑";
    },
    formButton() {
      return "更改";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.desserts = [];
      this.$axios({
        method: "get",
        url: "/api/manage/ship_name/list",
        data: {}
      })
        .then(res => {
          if (res.data.code == 0) {
            var tmp = JSON.parse(JSON.stringify(res.data.data));
            // console.log(tmp)
            for (let i in tmp) {
              let tmp_data = {
                ShipId: i,
                ShipName: tmp[i]
              };
              // tmp[i].Created = this.timestamp(tmp[i].Created);
              this.desserts.push(tmp_data);
            }
            // this.desserts = tmp;
          } else {
            this.alertText = res.data.msg;
            this.snackbar = true;
          }
          // this.desserts = JSON.stringify(res.data.data); 对象转json字符串
        })
        .catch(res => {
          // console.log(res.statu)
          this.alertText = "服务端错误！";
          this.snackbar = true;
          console.log(res);
        });
    },

    initializeNoZhcn() {
      this.desserts = [];
      this.$axios({
        method: "get",
        url: "/api/manage/ship_name/nozhcn",
        data: {}
      })
        .then(res => {
          if (res.data.code == 0) {
            var tmp = JSON.parse(JSON.stringify(res.data.data));
            // console.log(tmp)
            for (let i in tmp) {
              let tmp_data = {
                ShipId: i,
                ShipName: tmp[i]
              };
              // tmp[i].Created = this.timestamp(tmp[i].Created);
              this.desserts.push(tmp_data);
            }
            // this.desserts = tmp;
          } else {
            this.alertText = res.data.msg;
            this.snackbar = true;
          }
          // this.desserts = JSON.stringify(res.data.data); 对象转json字符串
        })
        .catch(res => {
          this.alertText = "服务端错误！";
          this.snackbar = true;
          console.log(res);
        });
    },

    checkNewShips() {
      this.$axios({
        method: "get",
        url: "/api/manage/ship_name/check",
        data: {}
      })
        .then(res => {
          if (res.data.code == 0) {
            this.initialize();
          } else {
            this.alertText = res.data.msg;
            this.snackbar = true;
          }
        })
        .catch(res => {
          this.alertText = "服务端错误！";
          this.snackbar = true;
          console.log(res);
        });
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.$axios({
        method: "post",
        url: "/api/manage/ship_name/update",
        data: JSON.stringify({
          ship_id: this.editedItem.ShipId,
          ship_name: this.editedItem.ShipName
        })
      })
        .then(res => {
          if (res.data.code == 0) {
            this.initialize()
            console.log('查询数据')
          } else {
            this.alertText = res.data.msg;
            this.snackbar = true;
          }
        })
        .catch(res => {
          this.alertText = "服务端错误！";
          this.snackbar = true;
          console.log(res);
        });
      this.close();
    }
  }
};
</script>
<style>
.frame {
  margin-top: 18px;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
