import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '../layout'
import Index from '../views/Index.vue'
import ShipName from '../views/ShipName.vue'
import ShipScore from '../views/ShipScore.vue'
import SysPublish from '../views/SysPublish.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Index',
    component: layout,
    redirect:"/index",
    children: [
      {
        path: 'index',
        name: 'index',
        component: Index
      },
      {
        path: 'ship_name',
        name: 'ShipName',
        component: ShipName
      },
      {
        path: 'ship_score',
        name: 'ShipScore',
        component: ShipScore
      },
      {
        path: 'sys_publish',
        name: 'SysPublish',
        component: SysPublish
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//挂在路由导航守卫
router.beforeEach((to, from, next) => {
  //to将要访问的路径
  //from代表从那个来
  //next是个函数   next()放行   next('/login')强制跳转
  if (to.path === '/login') {
      return next(); //放行
  }
  const tokenStr = window.sessionStorage.getItem('token');
  if (!tokenStr) {
      return next('/login');
  }
  return next();
})

export default router
