<template>
  <div>
    空空如也
  </div>
</template>

<script>
export default {
  name: "Index",

};
</script>
