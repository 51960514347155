import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

// Vue.prototype.$axios = axios
// axios.defaults.baseURL = 'http://127.0.0.1:8888'; //后端开发环境地址
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'; //配置请求头信息。
axios.interceptors.request.use(
  config => {
    // console.log(config);
    if (window.sessionStorage.getItem('token')) {
      config.headers.Authorization = 'Bearer ' + window.sessionStorage.getItem('token');
    }
    return config;
  },
  function (err) {
    console.log("失败信息" + err);
  }
);

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          router.replace({
            path: '/login',
            // query: {
            //   redirect: router.currentRoute.fullPath
            // }
          })
      }
    }
    return Promise.reject(error.response.data)
  }
);

Vue.prototype.timestamp = function (stamp) {
  var date = new Date(stamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  var Y = date.getFullYear() + '-';
  var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  var D = date.getDate() + ' ';
  var h = date.getHours() + ':';
  var m = date.getMinutes() + ':';
  var s = date.getSeconds();
  return Y + M + D + h + m + s;
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
