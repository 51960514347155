<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    sort-desc
    class="elevation-1 frame"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>公告管理</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="850px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              新建公告
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.Title"
                      label="标题"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="10">
                    <!-- <v-text-field
                      v-model="editedItem.content"
                      label="content"
                    ></v-text-field> -->
                    <!-- <v-textarea
                      label="内容"
                      v-model="editedItem.Content"
                    ></v-textarea> -->
                    <quill-editor
                      ref="text"
                      v-model="editedItem.Content"
                      class="myQuillEditor"
                      :options="editorOption"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> 取消 </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                {{ formButton }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">确定要删除此公告记录？</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >取消</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >确定</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <!-- <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon> -->
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      暂无公告
      <!-- <v-btn color="primary" @click="initialize"> 重置 </v-btn> -->
    </template>
    <v-snackbar v-model="snackbar" :timeout="timeout" :multi-line="multiLine">
      {{ alertText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-data-table>
</template>
<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  name: "SysPublish",
  components: { quillEditor },
  data: () => ({
    editorOption: {
      modules: {
        toolbar: [
          [
            "bold",
            "italic",
            "underline",
            "strike",
            // "link",
            // "image",
            // "code-block",
          ], // toggled buttons
          [{ align: [] }], //对齐方式
          [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
          [{ font: [] }], //字体
          [{ size: ["small", "normal", "large", "huge"] }],
          [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
        ],
      },
    },

    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "公告标题",
        align: "start",
        sortable: false,
        value: "Title",
      },
      { text: "公告内容", sortable: false, value: "Content" },
      { text: "发布时间", sortable: true, value: "Created" },
      { text: "操作", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      Title: "",
      Content: "",
    },
    defaultItem: {
      Title: "",
      Content: "",
    },

    multiLine: true,
    snackbar: false,
    timeout: 2000,
    alertText: `I'm a multi-line snackbar.`,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "新建公告" : "编辑公告";
    },
    formButton() {
      return this.editedIndex === -1 ? "发布" : "再次发布";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$axios({
        method: "get",
        url: "/api/manage/note/list",
        data: {},
      })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(this.desserts)
            var tmp = JSON.parse(JSON.stringify(res.data.data));
            for (let i in tmp) {
              tmp[i].Created = this.timestamp(tmp[i].Created);
            }
            this.desserts = tmp;
            // console.log(this.desserts[0])
            // for (let i in res.data.data) {
            //   let tmp = JSON.parse(JSON.stringify(res.data.data[i]));
            //   tmp.Created = this.timestamp(tmp.Created);
            //   this.desserts.push(tmp);
            //   // console.log(tmp)
            // }
          } else {
            this.alertText = res.data.msg;
            this.snackbar = true;
          }
          // this.desserts = JSON.stringify(res.data.data); 对象转json字符串
        })
        .catch((res) => {
          this.alertText = "服务端错误！";
          this.snackbar = true;
          console.log(res);
        });
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$axios({
        method: "get",
        url: "/api/manage/note/del?id=" + this.desserts[this.editedIndex].ID,
        data: {},
      })
        .then((res) => {
          if (res.data.code == 0) {
            // window.location.reload();
          } else {
            this.alertText = res.data.msg;
            this.snackbar = true;
          }
          // this.desserts = JSON.stringify(res.data.data); 对象转json字符串
        })
        .catch((res) => {
          this.alertText = "服务端错误！";
          this.snackbar = true;
          console.log(res);
        });
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // console.log(this.editedItem)
        // return
        this.$axios({
          method: "post",
          url: "/api/manage/note/pub",
          data: JSON.stringify(this.editedItem),
        })
          .then((res) => {
            if (res.data.code == 0) {
              window.location.reload();
            } else {
              this.alertText = res.data.msg;
              this.snackbar = true;
            }
            // this.desserts = JSON.stringify(res.data.data); 对象转json字符串
          })
          .catch((res) => {
            this.alertText = "服务端错误！";
            this.snackbar = true;
            console.log(res);
          });
        // Object.assign(this.desserts[this.editedIndex], this.editedItem);
        // console.log("edited and save note");
      } else {
        // console.log(this.editedItem)
        // return
        // this.desserts.push(this.editedItem);
        // console.log("save note");
        this.$axios({
          method: "post",
          url: "/api/manage/note/pub",
          data: JSON.stringify(this.editedItem),
        })
          .then((res) => {
            if (res.data.code == 0) {
              window.location.reload();
            } else {
              this.alertText = res.data.msg;
              this.snackbar = true;
            }
            // this.desserts = JSON.stringify(res.data.data); 对象转json字符串
          })
          .catch((res) => {
            this.alertText = "服务端错误！";
            this.snackbar = true;
            console.log(res);
          });
      }
      this.close();
    },
  },
};
</script>
<style>
.frame {
  margin-top: 18px;
  margin-left: 10px;
  margin-right: 10px;
}
</style>
