<template>
  <div style="width: 30%; margin-top: 12%; margin-left: 35%">
    <h1 style="text-align: center">后台管理系统</h1>
    <br />
    <v-divider></v-divider>
    <br />
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
        v-model="loginData.account"
        :counter="10"
        :rules="accountRules"
        label="账号"
        required
      ></v-text-field>

      <v-text-field
        v-model="loginData.password"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[passwordRules.required, passwordRules.min]"
        :type="show1 ? 'text' : 'password'"
        name="input-10-1"
        label="密码"
        hint="最少8个字符"
        counter
        @click:append="show1 = !show1"
      ></v-text-field>

      <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
        登录
      </v-btn>

      <v-btn color="error" class="mr-4" @click="reset"> 重置 </v-btn>
    </v-form>
    <br />
    <br />
    <v-footer padless>
      <v-col class="text-center" cols="12">
        {{ new Date().getFullYear() }} — <strong>舰队锚地</strong>
      </v-col>
    </v-footer>
    <v-snackbar v-model="snackbar" :timeout="timeout" :multi-line="multiLine">
      {{ alertText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import sha1 from "sha1";
export default {
  name: "Login",

  data: () => ({
    valid: true,
    show1: false,
    loginData: {
      account: "",
      password: "",
    },
    // account: "",
    accountRules: [
      (v) => !!v || "账号不能为空",
      (v) => (v && v.length <= 10) || "最多10个字符",
    ],
    // password: "",
    passwordRules: {
      required: (value) => !!value || "密码不能为空",
      min: (v) => v.length >= 8 || "最少8个字符（Min 8 characters）",
      // emailMatch: () => (`The email and password you entered don't match`),
    },

    multiLine: true,
    snackbar: false,
    timeout: 2000,
    alertText: `I'm a multi-line snackbar.`,
  }),

  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate() == true) {
        this.loginData.password = sha1(this.loginData.password);
        this.$axios({
          method: "post",
          url: "/api/manage/login",
          data: JSON.stringify(this.loginData),
        })
          .then((res) => {
            if (res.data.code == 0) {
              window.sessionStorage.setItem("token",res.data.token)
              this.$router.push({
                path: "/",
              });
            } else {
              this.alertText = res.data.msg;
              this.snackbar = true;
            }
            // this.desserts = JSON.stringify(res.data.data); 对象转json字符串
          })
          .catch((res) => {
            this.alertText = "服务端错误！";
            this.snackbar = true;
            console.log(res);
          });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>